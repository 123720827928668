<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>사용자 가입 승인</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="width: 1330px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label style="width: 100px">{{ detailsFieldMap.userId }}</label>
								<input
									type="text"
									id="startInput"
									class="form-control"
									style="width: 190px"
									:placeholder="detailsFieldMap.userId"
									v-model="searchVM.userId"
								/>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label style="width: 100px">{{ detailsFieldMap.userNm }}</label>
								<input type="text" class="form-control" style="width: 170px" :placeholder="detailsFieldMap.userNm" v-model="searchVM.userNm" />
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label style="width: 100px">{{ detailsFieldMap.hpNo }}</label>
								<input
									type="text"
									class="form-control"
									style="width: 172px"
									:placeholder="detailsFieldMap.hpNo"
									v-model="searchVM.hpNo"
									@keyup="hpNoDataChk('hpNo', $event)"
								/>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label style="width: 160px">{{ detailsFieldMap.aprvlDvsnCd }}</label>
								<select2 v-model="searchVM.aprvlDvsnCd">
									<option value="">전체</option>
									<option v-for="(row, index) in detailsOptions.aprvlDvsnCdOptions" :key="index" :value="row.value">{{ row.text }}</option>
								</select2>
							</div>
						</div>
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<KendoGrid
				ref="grid"
				:auto-bind="false"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li @click="pannelHidden"><a data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body panel-body-center">
					<div class="row panel-detail">
						<fieldset>
							<legend class="text-semibold col-md-12">
								<i class="icon-cog3 position-left"></i>
								사용자 가입 정보
							</legend>
							<div class="col-md-6">
								<!-- 역할(추가시) -->
								<jarvis-field :label="detailsFieldMap.userRoleCd" field="detailsItem.userRoleCd" required="true">
									<select2 v-model="detailsItem.userRoleCd" v-validate="validationRule.detailsItem.userRoleCd" data-vv-name="detailsItem.userRoleCd">
										<option :value="null">선택</option>
										<option v-for="(row, index) in detailsOptions.userRoleCdOptions" :key="index" :value="row.cd">
											{{ row.cdNm }}({{ row.cd }})
										</option>
									</select2>
								</jarvis-field>
								<jarvis-field
									:label="detailsFieldMap.userId"
									id="firstInputCreate"
									required="true"
									field="detailsItem.userId"
									v-model="detailsItem.userId"
									data-vv-name="detailsItem.userId"
									:disabled="!isCreateMode"
									v-validate="validationRule.detailsItem.userId"
								></jarvis-field>
								<!-- 사용자명 -->
								<jarvis-field
									:label="detailsFieldMap.userNm"
									id="firstInputEdit"
									required="true"
									field="detailsItem.userNm"
									v-model="detailsItem.userNm"
									data-vv-name="detailsItem.userNm"
									v-validate="validationRule.detailsItem.userNm"
								></jarvis-field>
								<!-- 현장 -->
								<jarvis-field :label="detailsFieldMap.siteId" :required="true" field="detailsItem.siteId" v-if="detailsItem.userRoleCd != '00'">
									<select2
										v-model="detailsItem.siteId"
										:options="detailsOptions.siteOptions"
										data-vv-name="detailsItem.siteId"
										v-validate="validationRule.detailsItem.siteId"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 업체 -->
								<jarvis-field :label="detailsFieldMap.companyId" :required="true" field="detailsItem.companyId">
									<select2
										v-model="detailsItem.companyId"
										:options="detailsOptions.companyOptions"
										data-vv-name="detailsItem.companyId"
										v-validate="validationRule.detailsItem.companyId"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 핸드폰 어플리케이션 로그인 로직 상 변경 불가하도록 해둠. -->
								<jarvis-field
									type="text"
									:label="detailsFieldMap.hpNo"
									required="true"
									field="detailsItem.hpNo"
									v-model="detailsItem.hpNo"
									data-vv-name="detailsItem.hpNo"
									:disabled="!isCreateMode"
									v-validate="validationRule.detailsItem.hpNo"
								></jarvis-field>
								<!-- 휴대폰플랫폼 -->
								<jarvis-field :label="detailsFieldMap.userMoblPlatfm" field="detailsItem.userMoblPlatfm">
									<select2
										v-model="detailsItem.userMoblPlatfm"
										data-vv-name="detailsItem.userMoblPlatfm"
										v-validate="validationRule.detailsItem.userMoblPlatfm"
										:options="detailsOptions.userMoblPlatfmOptions"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- apple id -->
								<jarvis-field
									v-if="detailsItem.userMoblPlatfm == '02'"
									:label="detailsFieldMap.appleId"
									required="true"
									field="detailsItem.appleId"
									v-model="detailsItem.appleId"
									data-vv-name="detailsItem.appleId"
									v-validate="validationRule.detailsItem.appleId"
								></jarvis-field>
							</div>

							<div class="col-md-6" style="padding-left: 70px">
								<!-- 연결된 권한, 체크박스 목록 -->
								<div class="from-group">
									<label class="col-lg-3 control-label text-semibold" style="width: 120px">화면 권한</label>
									<div class="col-lg-9" style="text-align: left; width: 250px">
										<div class="checkbox" v-for="item in pageParam.roleGroupCodeOptions" :key="item.text">
											<label>
												<input type="checkbox" v-model="detailsItem.roleGrpCds" :value="item.value" />
												{{ item.value }} ({{ item.text }})
											</label>
										</div>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
					<div class="text-right">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button
							v-if="this.detailsItem.aprvlDvsnCd != '03'"
							type="button"
							class="btn btn-labeled bg-warning mx-sm-1"
							@click="rejectRegisterUser()"
						>
							<b><i class="icon-minus3"></i></b>
							거절
						</button>
						<button v-if="this.detailsItem.aprvlDvsnCd != '03'" type="button" class="btn btn-labeled bg-info mx-sm-1" @click="holdRegisterUser()">
							<b><i class="icon-checkmark3"></i></b>
							보류
						</button>
						<button v-if="this.detailsItem.aprvlDvsnCd != '03'" type="button" class="btn btn-labeled bg-primary" @click="allowRegisterUser()">
							<b><i class="icon-checkmark3"></i></b>
							승인
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention;
const registerUrl = apiIndex.registerUser;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		userRoleCd: 'required',
		userId: 'max:30|userIdCheck|required',
		userNm: 'max:50|required',
		companyId: 'required',
		hpNo: 'required|mobileNoNumPattern|max:11',
		siteId: 'required',
		appleId: 'required|email|max:100',
	},
	edit: {},
	create: {},
};

_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		Select2: Select2,
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	data: () => ({
		pageParam: null,
		//API목록
		apiUrl: {
			pageListApi: registerUrl.inqRegisterUser,
		},
		//검색 VM
		searchVM: {
			userId: '',
			userNm: '',
			hpNo: '',
			siteNm: '',
			companyNm: '',
			userRoleCd: '',
			aprvlDvsnCd: '01',
		},
		//적용된 검색조건
		searchState: {},
		// 그리드 맵핑 정보
		gridColumns: [
			{ field: 'userId', title: '사용자ID' },
			{ field: 'userNm', title: '사용자명' },
			{ field: 'hpNo', title: '연락처', dataFormat: 'hyphenPhone', align: 'center' },
			{ field: 'userMoblPlatfm', title: '휴대폰 플랫폼', dataFormat: 'optionMap', align: 'center' },
			{ field: 'userRoleCd', title: '역할', dataFormat: 'optionMap', align: 'center' },
			{ field: 'aprvlDvsnCd', title: '승인구분코드', dataFormat: 'optionMap', align: 'center' },
		],
		//상세 select options
		detailsOptions: {},
		//상세필드명맵
		detailsFieldMap: {
			userId: '사용자ID',
			companyId: '업체ID',
			companyNm: '업체명',
			hpNo: '휴대폰번호',
			userNm: '사용자명',
			userRoleCd: '역할',
			userMoblPlatfm: '휴대폰플랫폼',
			siteId: '현장ID',
			siteNm: '현장명',
			aprvlDvsnCd: '승인구분코드',
			appleId: '애플ID',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {
			userId: '',
			companyId: '',
			siteId: '',
			hpNo: '',
			pwd: '',
			userNm: '',
			userRoleCd: null,
			userMoblPlatfm: null,
			userMoblTokn: null,
			delYn: '',
			roleGrpCds: [],
			appleId: null,
		},
		debug: false,
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('registerUserPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;

		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });

		this.pageParam.userMoblPlatfmOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});

		this.pageParam.userRoleCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});

		this.pageParam.aprvlDvsnCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});

		this.pageParam.siteOptions.forEach(e => {
			e.text = e.siteNm + '(' + e.siteId + ')';
			e.value = e.siteId;
		});

		// const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
		this.detailsOptions = {
			companyOptions: this.pageParam.companyOptions,
			roleGroupCodeOptions: this.pageParam.roleGroupCodeOptions,
			userRoleCdOptions: this.pageParam.userRoleCdOptions,
			userMoblPlatfmOptions: this.pageParam.userMoblPlatfmOptions,
			aprvlDvsnCdOptions: this.pageParam.aprvlDvsnCdOptions,
			siteOptions: this.pageParam.siteOptions,
		};
		// //기본 현장 세팅
		// this.searchVM.siteNm = this.detailsOptions.siteOptions.filter(e => e.siteId == this.pageParam.loginUserSiteId)[0].siteNm;
		// this.searchOptions = {
		// 	delYn: this.detailsOptions.delYn,
		// };
		// this.detailsOptionsWithoutManager = this.detailsOptions.userRoleCdOptions.slice(1, this.detailsOptions.userRoleCdOptions.length);
		this.gridColumns.filter(item => item.field == 'userMoblPlatfm')[0].mapData = this.detailsOptions.userMoblPlatfmOptions;
		this.gridColumns.filter(item => item.field == 'userRoleCd')[0].mapData = this.detailsOptions.userRoleCdOptions;
		this.gridColumns.filter(item => item.field == 'aprvlDvsnCd')[0].mapData = this.detailsOptions.aprvlDvsnCdOptions;

		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.search();
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});
	},
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			// return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
			return this.detailsItem.userRoleCd != null && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				if (this.detailsItem.isChangePwd) {
					rule = $.extend(true /*deep*/, { pwd: 'required|min:4|max:20' }, detailsValidationRule.edit);
				} else {
					rule = detailsValidationRule.edit;
				}
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		hpNoDataChk(data, e) {
			let target = e.target;
			let value = target.value;

			value = this.$jarvisExtention.inputDataController.onlyNumLimitLen(value);

			this.detailsItem[data] = value;
			target.value = value;
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.$nextTick(function () {
				this.loadGrid();
			});
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				if (selectedRowItem.aprvlDvsnCd == '02') {
					alert('승인된 사용자의 정보는 사용자 관리에서 확인해주세요');
					return;
				}
				//수정시작
				this.$axios
					.get(registerUrl.inqOneRegisterUser, { params: { userReqSeq: selectedRowItem.userReqSeq } })
					.then(
						function (response) {
							this.detailsItemOriginal = $.extend(true /*deep*/, { roleGrpCds: [] }, response.data);
							this.detailsItem = $.extend(true /*deep*/, { roleGrpCds: [] }, response.data);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		// 승인
		allowRegisterUser() {
			this.$validator.validateAll().then(success => {
				if (success == false) {
					return;
				}

				if (confirm('회원가입을 승인하시겠습니까?')) {
					this.$axios
						.put(registerUrl.allowRegisterUser, this.detailsItem)
						.then(
							function () {
								this.loadGrid();
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
				}
			});
		},
		// 보류
		holdRegisterUser() {
			if (confirm('회원가입을 보류하시겠습니까?')) {
				this.$axios
					.put(registerUrl.holdRegisterUser, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		// 거절
		rejectRegisterUser() {
			if (confirm('회원가입을 거절하시겠습니까?')) {
				this.$axios
					.put(registerUrl.rejectRegisterUser, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	margin-top: 5px;
	max-width: 130px;
}
</style>
